import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'chaplain-create',
  templateUrl: './chaplain-create.component.html',
  styleUrls: ['./chaplain-create.component.scss']
})
export class ChaplainCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  employmentId: string;
  images = [];
  key:string;
  name: string;
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  fileToUpload: File = null;
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;

  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;

  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': [''],
      'phone': ['',[Validators.required, Validators.pattern("[- +()0-9]+")]],
      'section': ['',Validators.required],
      'link': ['',''],
      'document': [''],
      // 'link_name': ['']
    });
  }

  updateKey (eventData) {
    this.key = eventData.toLowerCase( ).replace(' ', '_');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        'desc': this.createPageForm.value.desc,
        // 'image': this.url,
        // 'link': this.createPageForm.value.link,
        'section_or_religion': this.createPageForm.value.section,
        'phone': this.createPageForm.value.phone,
        // 'type': "chaplain"
        'type': this.key,
        'document': this.documentUploaded['document'],
        'document_name': this.documentUploaded['doc_name']
      }
      this.apiService.postRequest(this.createUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                  this.router.navigate(['wellness-pages/content',content.id + "-" + content.slug]);
              }
              this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
}
