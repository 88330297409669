import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'resource-create',
  templateUrl: './resource-create.component.html',
  styleUrls: ['./resource-create.component.scss']
})
export class ResourceCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  url: string  = "";
  key:string;
  name: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;

  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.createPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'document': [''],
      'desc': [''],
      'link': [''],
      'link_name': ['']
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Resource";
    // }
  }

  updateKey (eventData) {
    this.key = eventData.toLowerCase( ).replace(' ', '_');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        'desc': this.createPageForm.value.desc,
        'link': this.createPageForm.value.link,
        'link_name': this.createPageForm.value.link_name,
        'type' : this.key
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      // if(!this.isStatic) {
      //   postData['type'] = this.key
      // } else {
      //   postData['type'] = 'resource'
      // }
      // if(!this.createUrl) {
      //   this.createUrl = 'admin/standard-page?key=' + this.key;
      // }
      this.apiService.postRequest(this.createUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['resource']);
                // }
              }
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.createPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
}
