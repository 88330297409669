import { Component, OnInit, Input,ElementRef,ViewChild, TemplateRef,NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import Player from '@vimeo/player'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-mindful-learning-create',
  templateUrl: './mindful-learning-create.component.html',
  styleUrls: ['./mindful-learning-create.component.scss']
})
export class MindfulLearningCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link']
  };
  createPageForm: FormGroup;
  link : any;
  private player: any;
  videoId : any;
  audioList = [];
  @ViewChild('vimeoPlayer', {static: false}) vimeoPlayer: ElementRef;
  @ViewChild('editVideoModal', { static: false }) videoEditModal: TemplateRef<any>;
  @ViewChild('deleteVideoModal', { static: false }) videoDeleteModal: TemplateRef<any>;
  @ViewChild('editAudioModal', { static: false }) audioEditModal: TemplateRef<any>;
  @ViewChild('deleteAudioModal', { static: false }) audioDeleteModal: TemplateRef<any>;
  @ViewChild('pageBackModel', { static: false }) pageBackModel: TemplateRef<any>;
  @ViewChild('documentDeleteModal', { static: false }) documentDeleteModal: TemplateRef<any>;
  @ViewChild('documentEditModal', { static: false }) documentEditModal: TemplateRef<any>;
  @ViewChild('mediaDeleteModal', { static: false }) mediaDeleteModal: TemplateRef<any>;
  @ViewChild('mediaEditModal', { static: false }) mediaEditModal: TemplateRef<any>;

  video : string;
  vimeo_url : string;
  video_extension : string;
  isVideo : boolean = false;
  accoModalRef: any;
  video_url : string;
  errorMessage = null;
  isAudio : boolean = false;
  isDocument : boolean = false;
  isMedia : boolean = false;
  document : string;
  document_extension : string;
  document_url : string;
  document_name : string;
  media : string;
  media_extension : string;
  media_url : string;
  media_name : string;
  audio : string;
  audio_extension : string;
  audio_url : string;
  canUploadVideo: boolean = true;
  canUploadAudio: boolean = true;
  canUploadDocument: boolean = true;
  canUploadMedia: boolean = true;
  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  @Input() nextChapter: string ;
  vimeo_status : string;
  processing: boolean = false;
  audio_name : string;
  hasWellnessAccess: boolean;
  hasInput: boolean = false;
  @Input() lessonRestricted: boolean = false;

  constructor(private authService: AuthService,private platformLocation: PlatformLocation, private elementRef: ElementRef, private modal: NgbModal, private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    let user = this.authService.getCurrentUser();
    let permissions = user.permission_list;
    this.hasWellnessAccess = permissions.indexOf("WELLNESS_ACCESS") !== -1;
    this.createPageForm = this.formBuilder.group({
      'title': ['',Validators.required],
      'description': ['',Validators.required],
      'audio' : [''],
      'video' : [''],
      'restricted' : ['']
    });
    this.platformLocation.onPopState(() => {
      this.deleteAttachment();
    });
    if(this.lessonRestricted) {
      this.createPageForm.controls['restricted'].disable();
    }
  }

  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  backButtonConfirmed(event:any) {
    this.closeModal();
    if(this.isAudio) {
      this.deleteUploadedAudio();
      setTimeout(() => {
        this.location.back();
      }, 100);
    } else if(this.isVideo) {
      this.deleteVimeoVideo();
      setTimeout(() => {
        this.location.back();
      }, 100);
    } else {
      this.deleteUploadedDocument();
      setTimeout(() => {
        this.location.back();
      }, 100);
    }
    
  }

  playVideo(event) {
    let videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.play();
    }
    videoElement.style.display ="none";
  }

  triggerVideoUpload() {
    if(!this.canUploadVideo) {
      this._flashMessagesService.show('Unable to choose a video since you have already uploaded an audio, media or document!', { cssClass: 'alert-danger' });
    } else {
      document.getElementById('attachment').click();
    }
  }

  triggerAudioUpload() {
    if(!this.canUploadAudio) {
      this._flashMessagesService.show('Unable to choose an audio since you have already uploaded a video, media or document!', { cssClass: 'alert-danger' });
    } else {
      document.getElementById('attachment').click();
    }
  }
  
  triggerAttachment() {
    document.getElementById('attachment').click();
  }

  editAttachment(event) {
    if(this.isAudio) {
      this.editAudio(event)
    } else if(this.isVideo) {
      this.editVideo(event)
    } else if(this.isDocument){
      this.editDocument(event)
    } else {
      this.editMedia(event)
    }
  }

  deleteAttachment() {
    if(this.isAudio) {
      this.deleteAudio(true)
    } else if(this.isVideo) {
      this.deleteVideo(true)
    } else if(this.isDocument)  {
      this.deleteDocument(true)
    } else {
      this.deleteMedia(true)
    }
  }
  uploadAttachment(event) {
    var ext = event.target.files[0].type
    let type = ext.split('/');
    if(type[0] == 'video') {
      this.videoUpload(event)
    } else if(type[0] == 'audio') {
      this.audioUpload(event)
    } else if(type[0] == 'application') {
      this.documentUpload(event)
    } else {
      this.mediaUpload(event)
    }
  }
  ngOnDestroy() {
    this.platformLocation.onPopState(null);
    return false;
  }

  audioUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('audio',event.target.files[0]);
      this.apiService.postRequest('admin/upload-audio', formData).subscribe(
        (response: any) => {
          if (response.success) {
            this.errorMessage = null;
            this.isAudio = true;
            this.audio = response.data.audio;
            this.audio_extension = response.data.audio_extension;
            this.audio_url = response.data.audio_url;
            this.processing = true;
            this.audio_name = response.data.audio_name
            // document.getElementById('audio-container').style.display = 'block';
            document.getElementById('attachment-title').innerHTML = response.data.file_name
            this.gotInput();
            // document.getElementById('uploaded-audio-name').innerHTML = response.data.file_name
            this.audioList = [
              {
                url: this.audio,
              }
            ];
            this.canUploadMedia = false
            this.canUploadVideo = false
            this.canUploadDocument = false
          }  else {
              this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
          }
        },
        err => {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          console.log(err);
        },
      );
    }
  }
  mediaUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('media',event.target.files[0]);
      this.apiService.postRequest('admin/upload-mindful-topic-media', formData).subscribe(
        (response: any) => {
          if (response.success) {
            this.errorMessage = null;
            this.isMedia = true;
            this.media = response.data.media;
            this.media_extension = response.data.media_extension;
            this.media_url = response.data.media_url;
            this.processing = true;
            this.media_name = response.data.media_name
            // document.getElementById('audio-container').style.display = 'block';
            document.getElementById('attachment-title').innerHTML = response.data.file_name
            this.gotInput();
            this.canUploadVideo = false
            this.canUploadAudio = false
            this.canUploadDocument = false
          }  else {
              this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
          }
        },
        err => {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          console.log(err);
        },
      );
    }
  }
  documentUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('document',event.target.files[0]);
      this.apiService.postRequest('admin/upload-mindful-topic-document', formData).subscribe(
        (response: any) => {
          if (response.success) {
            this.errorMessage = null;
            this.isDocument = true;
            this.document = response.data.document;
            this.document_extension = response.data.document_extension;
            this.document_url = response.data.document_url;
            this.processing = true;
            this.document_name = response.data.document_name
            // document.getElementById('audio-container').style.display = 'block';
            document.getElementById('attachment-title').innerHTML = response.data.file_name
            this.gotInput();
            this.canUploadVideo = false
            this.canUploadMedia = false
            this.canUploadAudio = false
          }  else {
              this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
          }
        },
        err => {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          console.log(err);
        },
      );
    }
  }

  videoUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('vimeo_file',event.target.files[0]);
      this.apiService.postRequest('admin/upload-video-to-vimeo', formData).subscribe(
        (response: any) => {
          this.errorMessage = null;
          if (response.success) {
            this.errorMessage = null;
            this.videoId = response.data.video_id;
            this.vimeo_status = response.data.status
            this.link = response.data.link;
            // this.video = response.data.video;
            this.vimeo_url = response.data.vimeo_url;
            this.video_extension = response.data.video_extension;
            this.video_url = response.data.video_url;
            this.isVideo = true;
            this.canUploadAudio = false
            this.canUploadDocument = false
            this.canUploadMedia = false
            this.processing = true;
            document.getElementById('attachment-title').innerHTML = response.data.file_name
            this.gotInput();
            // if(response.data.status == "in_progress") {
            //   this.errorMessage = "The video is under transcoding by vimeo. It might take 2-3 minutes to complete";
            // } else if(response.data.status == "error") {
            //   this.isVideo = false
            //   this.errorMessage = "Unable to find this video in vimeo.";
            // }
          }  else {
              this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
          }
        },
        err => {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          console.log(err);
        },
      );
    }
  }
  editVideo(event) {
    this.accoModalRef = this.modal.open(this.videoEditModal);
  }
  editDocument(event) {
    this.accoModalRef = this.modal.open(this.documentEditModal);
  }
  editMedia(event) {
    this.accoModalRef = this.modal.open(this.mediaEditModal);
  }
  editAudio(event) {
    this.accoModalRef = this.modal.open(this.audioEditModal);
  }
  deleteVideo(event) {
    this.accoModalRef = this.modal.open(this.videoDeleteModal);
  }
  deleteDocument(event) {
    this.accoModalRef = this.modal.open(this.documentDeleteModal);
  }
  deleteMedia(event) {
    this.accoModalRef = this.modal.open(this.mediaDeleteModal);
  }
  deleteAudio(event) {
    this.accoModalRef = this.modal.open(this.audioDeleteModal);
  }
  closeModal(result?: any) {
    this.accoModalRef.close();
  }
  editVimeoVideo() {
    var response = this.deleteVimeoVideo(false);
    this.triggerVideoUpload();
  }
  editUploadedAudio() {
    var response = this.deleteUploadedAudio(false);
    this.triggerAudioUpload();
  }
  editUploadedDocument() {
    var response = this.deleteUploadedDocument(false);
    this.triggerDocumentUpload();
  }
  editUploadedMedia() {
    var response = this.deleteUploadedMedia(false);
    this.triggerMediaUpload();
  }
  triggerDocumentUpload() {
    if(!this.canUploadDocument) {
      this._flashMessagesService.show('Unable to choose an document since you have already uploaded a video, media or audio!', { cssClass: 'alert-danger' });
    } else {
      document.getElementById('attachment').click();
    }
  }
  triggerMediaUpload() {
    if(!this.canUploadMedia) {
      this._flashMessagesService.show('Unable to choose an media since you have already uploaded a video,document, or audio!', { cssClass: 'alert-danger' });
    } else {
      document.getElementById('attachment').click();
    }
  }
  deleteVimeoVideo(isdelete = true) {
    let postData = {
      'videoId': this.videoId,
      'video' : this.video_url
    }
    this.apiService.postRequest('admin/delete-video-from-vimeo', postData).subscribe(
      (response: any) => {
        this.videoId = null;
        this.link = null;
        this.video = null;
        this.vimeo_url = null;
        this.video_extension = null;
        this.video_url = null;
        this.processing = false;
        // document.getElementById('vimeo-container').style.display = 'none';
        document.getElementById('attachment-title').innerHTML = null
        // document.getElementById('uploaded-video-name').innerHTML = null;
        this.gotInput();
        this.isVideo = false;
        this.closeModal();
        if(isdelete) {
          if(response.success) {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          }
        }
        this.canUploadAudio = true
        this.canUploadDocument = true
        this.canUploadMedia = true
      },
      err => {
          console.log(err);
          return false;
        },
    );
  }
  deleteUploadedAudio(isdelete = true) {
    let postData = {
      'audio' : this.audio_name
    }
    this.apiService.postRequest('admin/delete-audio', postData).subscribe(
      (response: any) => {
        this.isAudio = false
        this.audio = null
        this.audio_extension = null
        this.processing = false;
        // document.getElementById('audio-container').style.display = 'none';
        document.getElementById('attachment-title').innerHTML = null
        // document.getElementById('uploaded-audio-name').innerHTML = null;
        this.gotInput();
        this.closeModal();
        if(isdelete) {
          if(response.success) {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          }
        }
        this.canUploadVideo = true
        this.canUploadDocument = true
        this.canUploadMedia = true
      },
      err => {
          console.log(err);
          return false;
        },
    );
  }
  deleteUploadedDocument(isdelete = true) {
    let postData = {
      'document' : this.document_name
    }
    this.apiService.postRequest('admin/delete-mindful-document', postData).subscribe(
      (response: any) => {
        this.isDocument = false
        this.document = null
        this.document_extension = null
        this.processing = false;
        document.getElementById('attachment-title').innerHTML = null
        this.gotInput();
        this.closeModal();
        if(isdelete) {
          if(response.success) {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          }
        }
        this.canUploadVideo = true
        this.canUploadAudio = true
        this.canUploadMedia = true
      },
      err => {
          console.log(err);
          return false;
        },
    );
  }
  deleteUploadedMedia(isdelete = true) {
    let postData = {
      'media' : this.media_name
    }
    this.apiService.postRequest('admin/delete-mindful-media', postData).subscribe(
      (response: any) => {
        this.isMedia = false
        this.media = null
        this.media_extension = null
        this.processing = false;
        document.getElementById('attachment-title').innerHTML = null
        this.gotInput();
        this.closeModal();
        if(isdelete) {
          if(response.success) {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          }
        }
        this.canUploadAudio = true;
        this.canUploadVideo = true
        this.canUploadDocument = true

      },
      err => {
          console.log(err);
          return false;
        },
    );
  }
  
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'title': this.createPageForm.value.title,
        'desc': this.createPageForm.value.description,
        'audio': this.audio,
        'video': this.videoId,
        'document': this.document,
        'media': this.media,
        'local_video' : this.video_url,
        'vimeo_status' : this.vimeo_status,
        'restricted' : this.createPageForm.value.restricted
        // 'type' : this.createPageForm.value.title.toLowerCase( ).replace(' ', '_')
      }
      this.apiService.postRequest(this.createUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                  this.router.navigate(['wellness-pages/content',content.id + "-" + content.slug]);
              }
              // this.router.navigate(['peer-support']);
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(this.hasInput) {
      this.accoModalRef = this.modal.open(this.pageBackModel);
    } else {
      if(!this.isStatic) {
        if(this.backUrl)  {
          this.router.navigate([this.backUrl]);
        }
      } else {
        this.location.back();
      }
    }
  }
  gotInput() {
    let title = this.createPageForm.value.title.trim();
    let description = this.createPageForm.value.description.trim();
    if(title || description || this.videoId || this.audio || this.document || this.media) {
      this.hasInput = true
    } else {
      this.hasInput = false
    }
  }
}
