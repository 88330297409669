import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../shared/services/admin.service';
@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  loginImages: FormGroup;
  createSettings: FormGroup;
  notifySettings: FormGroup;
  errorMessage = null;
  facebook_url: string;
  website: string;
  offDutyTitle="";
  tip_master_mail: string;
  zone_master_mail: string;
  offduty_master_link: string;
  offduty_master_link_name: string;
  inmate_info_master_link: string;

  facebookAppId : number;
  facebookCode : string;
  linkedInCode : string;
  linkedinClientId : string;
  twitterOauthToken : string;
  twitterOauthVerifier : string;
  returnUrl  : string;
  fbstate = "555412";
  lnstate = "555321";
  twstate = "555234"
  oauthToken : string;
  fbUrl :string;
  twUrl :string;
  lnUrl :string;

  tipsms : boolean= false;
  tipmail : boolean= false;
  jobsms : boolean= false;
  jobmail : boolean= false;
  zonesms : boolean= false;
  zonemail : boolean= false;
  hasOffDutyAccess: boolean;

  tipNotify: string[];
  tipvalue: string = '';
  jobNotify: string[];
  jobvalue: string = '';

  zoneNotify: string[];
  zonevalue: string = '';
  notifySettingsSaveBtn: boolean = false;
  tipSaveBtn: boolean = false;
  jobSaveBtn: boolean = false;
  zoneSaveBtn: boolean = false;

  hasSocialMediaAcess = false;
  facebookLinked : boolean= false;
  twitterLinked : boolean= false;
  linkedinLinked : boolean= false;
  appSecret : string;
  zoneCheckText : string;
  isDocUploaded = false;
  fileToUpload: File = null;

  adminWithZoneCheckAccess : any;
  adminWithTipAccess : any;
  adminWithOffDutyAccess : any;
  users : any;
  specialPermissions: FormGroup;
  hasTipAccess: boolean;
  hasZoneCheckAccess: boolean;

  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;
  apiLink:any;
  hasSuperAdminAccess : boolean;
  loggedUserId : string;
  toggleSelected : any;
  confirmPermissionChangeForm: FormGroup;
  modalRef: any
  isAdminImageUploaded = false;
  isAppImageUploaded = false;
  isAppHomeImageUploaded = false;
  admin_login_url = '';
  app_login_url = '';
  app_home_url = '';
  app_login_url_default = '';
  app_home_url_default = '';
  isZoneExternal: boolean;
  showInmateInSettings: boolean = false;
  showOffdutyInSettings: boolean = false;
  wasPopulated: boolean = false;
  loginBg;

  @ViewChild('confirmModal', {static: false}) confirmModal : TemplateRef<any>;

  constructor( private modal: NgbModal,private authService: AuthService, private apiService: ApiService, private route:ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder,
    private adminService: AdminService) {
      this.loginBg = environment.loginBg;
     }

  ngOnInit() {
    this.offDutyTitle = environment.offDutyTitle;
    let user = this.authService.getCurrentUser();
    this.loggedUserId = user.id;
    let permissions = user.permission_list;
    let specialPermissions = this.authService.getUserSpecificRole();
    this.hasSocialMediaAcess = specialPermissions.indexOf("SOCIAL_MEDIA_ALLOW") !== -1;
    this.hasTipAccess = permissions.indexOf("TIP_ACCESS") !== -1;
    this.hasOffDutyAccess = specialPermissions.indexOf("OFF_DUTY_ALLOW") !== -1;
    this.hasSuperAdminAccess = permissions.indexOf("SUPER_ADMIN_ACCESS") !== -1;
    this.hasZoneCheckAccess = specialPermissions.indexOf("ZONE_CHECK_ALLOW") !== -1;
    this.isZoneExternal = environment.isZoneExternal;
    this.showInmateInSettings = environment.showInmateInSettings;
    this.showOffdutyInSettings = environment.showOffdutyInSettings;

    // if (environment.appName == "East Ridge") {
    //   this.zoneCheckText = "Watch";
    // }
    // else if (environment.appName == "MILTON") {
    //   this.zoneCheckText = "Vacation Check";
    // }
    // else {
      this.zoneCheckText = environment.zoneText;
    // }

    this.loginImages = this.formBuilder.group({
      'admin_login': [''],
      'app_login': [''],
      'app_home': ['']
    });

    this.createSettings = this.formBuilder.group({
      'facebook_url': [''],
      'website': [''],
      'tip_master_mail': ['', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      'zone_master_mail': ['', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      'offduty_master_link': [''],
      'offduty_master_link_name': [''],
      'inmate_info_master_link': ['']
    });

    this.specialPermissions = this.formBuilder.group({
      'user': ['',Validators.required],
      'permission': ['',Validators.required]
    });

    this.notifySettings = this.formBuilder.group({
      'tip_sms': [''],
      'tip_mail': [''],
      'job_sms': [''],
      'job_mail': [''],
      'zone_sms': [''],
      'zone_mail': ['']
    });
    this.facebookAppId  = environment.FacebookAppId;
    this.appSecret  = environment.FacebookAppSecret;
    this.linkedinClientId = environment.LinkedinClientId;
    this.returnUrl  = environment.ReturnUrl;
    console.log("Return Url :", this.returnUrl);
    // facebook
    if (this.route.snapshot.queryParams['state'] && this.route.snapshot.queryParams['code']) {
      if (this.route.snapshot.queryParams['state'] == this.fbstate) {
        this.facebookCode = this.route.snapshot.queryParams['code'];
        this.saveFacebookToken();
      } else if (this.route.snapshot.queryParams['state'] == this.lnstate) {
        this.linkedInCode = this.route.snapshot.queryParams['code'];
        this.saveLinkedinToken();
      }
    } else if (this.route.snapshot.queryParams['oauth_token'] && this.route.snapshot.queryParams['oauth_verifier']) { // twitter
      this.twitterOauthToken = this.route.snapshot.queryParams['oauth_token']; //validate with state aswell
      this.twitterOauthVerifier = this.route.snapshot.queryParams['oauth_verifier'];
      this.saveTwitterToken();
    } else if (this.route.snapshot.queryParams['error'] == "access_denied") {
      this.checkMediaTokens();
      this._flashMessagesService.show('Permission Denied!', { cssClass: 'alert-danger' });
    } else {
      if (this.hasSocialMediaAcess) {
        this.checkMediaTokens();
      }
    }
    this.getSettings();
    this.getNotificationSettings();
    this.getAdminUsers();

    this.confirmPermissionChangeForm = this.formBuilder.group({
      'user': ['', Validators.required],
      'permission': ['', Validators.required],
      'status': [''],
    });
    this.getLoginImages();
  }
  getLoginImages() {
    this.apiService.getRequest('admin/get-login-images').subscribe(
      (response: any) => {
        if(response.success) {
          this.admin_login_url = response.data.admin_login_url;
          this.app_login_url = response.data.app_login_url;
          this.app_home_url = response.data.app_home_url;
          this.app_login_url_default = response.data.app_login_url_default;
          this.app_home_url_default = response.data.app_home_url_default;
        }
      },
      (err: any) => console.log(err),
    );
  }

  getSettings () {
    this.apiService.getRequest('admin/settings').subscribe(
      (response: any) => {
        if(response.success) {
          this.facebook_url = response.data.facebook_url;
          this.website = response.data.website;
          this.tip_master_mail = response.data.tip_master_mail;
          this.zone_master_mail = response.data.zone_master_mail;
          this.offduty_master_link = response.data.offduty_master_link;
          this.offduty_master_link_name = response.data.offduty_master_link_name;
          this.inmate_info_master_link = response.data.inmate_info_master_link;
          if(this.offduty_master_link) {
            console.log(this.offduty_master_link);
            this.adminService.hideOffdutyMenu();
            this.hasOffDutyAccess = false;
          }
          else {
            this.adminService.showOffdutyMenu();
            this.hasOffDutyAccess = true;
          }
          if((this.facebook_url || this.website || this.tip_master_mail || this.zone_master_mail || this.offduty_master_link || this.inmate_info_master_link) != null) {
            this.wasPopulated = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  checkMediaTokens(checklinked = false){
    let route = '';
    if (checklinked) {
      route = 'admin/accounts-statuses?checklinked=true';
    } else {
      route = 'admin/accounts-statuses';

    }
    this.apiService.getRequest(route).subscribe(
      (response: any) => {
        if(response.success) {
          this.facebookLinked = response.data.facebook;
          this.twitterLinked = response.data.twitter;
          this.linkedinLinked = response.data.linkedin;
        }
      },
      (err: any) => console.log(err),
    );
  }
  saveFacebookToken () {
    let postData = {
      'fbCode': this.facebookCode
    }
    console.log("Saving facebook token with code:::::::::::",this.facebookCode);
    this.apiService.postRequest('admin/save-fb-token', postData).subscribe(
      (response: any) => {
          if (response.success) {
              this._flashMessagesService.show('Thank you for logging in. Facebook account is linked!', { cssClass: 'alert-success' });
              this.checkMediaTokens();
              this.errorMessage = null;
          } else {
              this._flashMessagesService.show(' Something went wrong, please try again after some time!', { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
              this.checkMediaTokens()
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          this.checkMediaTokens()
      },
    );
  }
  saveLinkedinToken () {
    let postData = {
      'lnCode': this.linkedInCode
    }
    console.log("Saving Linkedin token with code:::::::::::",this.linkedInCode);
    this.apiService.postRequest('admin/save-ln-token', postData).subscribe(
      (response: any) => {
          if (response.success) {
              this._flashMessagesService.show('Thank you for logging in. Linkedin account is linked!', { cssClass: 'alert-success' });
              this.errorMessage = null;
              setTimeout('', 2000); //linked in check token returning revoken when called as soon as token generated. So delaying token check with 2 seconds
              this.checkMediaTokens(true);
          } else {
              this._flashMessagesService.show(' Something went wrong, please try again after some time!', { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
              this.checkMediaTokens()
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          this.checkMediaTokens()
      },
    );
  }
  saveTwitterToken () {
    let postData = {
      'oauthToken': this.twitterOauthToken,
      'oauthVerifier' : this.twitterOauthVerifier
    }
    console.log("Saving twitter token with oauth:::::::::::",this.twitterOauthToken, this.twitterOauthVerifier);
    this.apiService.postRequest('admin/save-tw-token', postData).subscribe(
      (response: any) => {
          if (response.success) {
              this._flashMessagesService.show('Thankyou for logging in. Twitter account is linked!', { cssClass: 'alert-success' });
              this.checkMediaTokens();
              this.errorMessage = null;
          } else {
              this._flashMessagesService.show(' Something went wrong, please try again after some time!', { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
              this.checkMediaTokens()
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          this.checkMediaTokens()
      },
    );
  }
  onFacebookLogin() {
    if(this.facebookAppId) {
      this.fbUrl = "https://www.facebook.com/v19.0/dialog/oauth?client_id="+ this.facebookAppId +"&redirect_uri="+ this.returnUrl + "&state=" + this.fbstate + "&scope=pages_read_engagement,pages_manage_posts,pages_show_list,business_management,instagram_basic,instagram_content_publish,email&auth_type=rerequest";
      window.open(this.fbUrl, "_blank");
    } else {
      this._flashMessagesService.show('The Facebook account is not configured with the app yet! Please connect with Stackbench if you wish to use this feature.', { cssClass: 'alert-danger' });
    }

  }

  onLinkedinLogin() {
    if(this.linkedinClientId) {
        this.lnUrl = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id="  + this.linkedinClientId + "&state=" + this.lnstate + "&redirect_uri=" + this.returnUrl + "&scope=w_member_social+email+r_basicprofile";
        window.open(this.lnUrl, "_blank");
    } else {
      this._flashMessagesService.show('The Linkedin account is not configured with the app yet! Please connect with Stackbench if you wish to use this feature.', { cssClass: 'alert-danger' });
    }
  }

  onTwitterLogin() {
    this.apiService.getRequest('admin/twiter-oauth-token').subscribe(
      (response: any) => {
        if(response.success) {
          this.twUrl = response.data;
          window.open(this.twUrl, "_blank");
        } else if (response.error) {
            this._flashMessagesService.show(response.error, { cssClass: 'alert-danger' });
        } else {
            this._flashMessagesService.show(' Something went wrong, please try again after some time!', { cssClass: 'alert-danger' });
        }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );
  }
  unlinkAccount(key) {
    let route = '';
    console.log(key);
    if (key == 'facebook') {
      route = 'admin/unlink-fb-account';
    } else if (key == 'twitter') {
      route = 'admin/unlink-tw-account';
    } else if (key == 'linkedin') {
      route = 'admin/unlink-ln-account';
    }
    if (route != '') {
      this.apiService.postRequest(route, []).subscribe(
        (response: any) => {
          if(response.success) {
            this.checkMediaTokens();
            this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show(' Something went wrong, please try again after some time!', { cssClass: 'alert-danger' });
          }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );

    }
  }

  getNotificationSettings () {
    this.apiService.getRequest('admin/notify-settings').subscribe(
      (response: any) => {
        if(response.success) {
          if(response.data.tip_notify != ''){
            this.tipNotify = response.data.tip_notify.split(',');
            if (this.tipNotify.includes('mail')) {
              // this.tipsms = false;
              this.tipmail = true;
              this.notifySettings.controls['tip_mail'].setValue('mail');
            }
            if(this.tipNotify.includes('sms')){
              this.tipsms = true;
              this.notifySettings.controls['tip_sms'].setValue('sms');
            }
            // else {
            //   this.tipsms = true;
            //   this.tipmail = false;
            //   this.notifySettings.controls['tip'].setValue('sms');
            // }
          }
          if(response.data.job_notify != ''){
            this.jobNotify = response.data.job_notify.split(',');
            if (this.jobNotify.includes('mail')) {
              this.jobmail = true;
              this.notifySettings.controls['job_mail'].setValue('mail');
            }
            if(this.jobNotify.includes('sms')){
              this.jobsms = true;
              this.notifySettings.controls['job_sms'].setValue('sms');
            }
            // if (response.data.job_notify == 'mail') {
            //   this.jobsms = false;
            //   this.jobmail = true;
            //   this.notifySettings.controls['job'].setValue('mail');
            // } else {
            //   this.jobsms = true;
            //   this.jobmail = false;
            //   this.notifySettings.controls['job'].setValue('sms');
            // }
          }
          if(response.data.zone_notify != ''){
            this.zoneNotify = response.data.zone_notify.split(',');
            if (this.zoneNotify.includes('mail')) {
              // this.tipsms = false;
              this.zonemail = true;
              this.notifySettings.controls['zone_mail'].setValue('mail');
            }
            if(this.zoneNotify.includes('sms')){
              this.zonesms = true;
              this.notifySettings.controls['zone_sms'].setValue('sms');
            }
            // else {
            //   this.tipsms = true;
            //   this.tipmail = false;
            //   this.notifySettings.controls['tip'].setValue('sms');
            // }
          }
          // if (response.data.zone_notify == 'mail') {
          //   this.zonesms = false;
          //   this.zonemail = true;
          //   this.notifySettings.controls['zone'].setValue('mail');
          // } else {
          //   this.zonesms = true;
          //   this.zonemail = false;
          //   this.notifySettings.controls['zone'].setValue('sms');
          // }
        }
      },
      (err: any) => console.log(err),
    );
  }

  onSubmitNotification() {
    console.log(this.notifySettings.value.tip);
    if (this.notifySettings.valid) {
      this.tipvalue = '';
      this.jobvalue = '';
      this.zonevalue = '';
      if(this.notifySettings.controls['tip_mail'].value)
        this.tipvalue = 'mail';
      if(this.notifySettings.controls['tip_sms'].value){
        if(this.tipvalue != '')
          this.tipvalue = this.tipvalue +','+'sms';
        else
          this.tipvalue = 'sms';
      }

      if(this.notifySettings.controls['job_mail'].value)
        this.jobvalue = 'mail';
      if(this.notifySettings.controls['job_sms'].value){
        if(this.jobvalue != '')
          this.jobvalue = this.jobvalue +','+'sms';
        else
          this.jobvalue = 'sms';
      }

      if(this.notifySettings.controls['zone_mail'].value)
        this.zonevalue = 'mail';
      if(this.notifySettings.controls['zone_sms'].value){
        if(this.zonevalue != '')
          this.zonevalue = this.zonevalue +','+'sms';
        else
          this.zonevalue = 'sms';
      }

      let postData = {
        'tip': this.tipvalue,
        'job': this.jobvalue,
        'zone': this.zonevalue
      }
      this.apiService.postRequest('admin/notify-settings', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.checkMediaTokens();
                this._flashMessagesService.show('Notification Settings Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Notification Settings Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onSubmit() {
    if (this.createSettings.valid) {
      let postData = {
        'facebook_url': this.createSettings.value.facebook_url,
        'website': this.createSettings.value.website,
        'tip_master_mail': this.createSettings.value.tip_master_mail,
        'zone_master_mail': this.createSettings.value.zone_master_mail,
        'offduty_master_link': this.createSettings.value.offduty_master_link,
        'offduty_master_link_name': this.createSettings.value.offduty_master_link_name,
        'inmate_info_master_link': this.createSettings.value.inmate_info_master_link,
      }
      this.apiService.postRequest('admin/settings', postData).subscribe(
        (response: any) => {
            if (response.success) {
              console.log(response);
              if(this.createSettings.value.offduty_master_link) {
                console.log(this.createSettings.value.offduty_master_link);
                this.adminService.hideOffdutyMenu();
              }
              else {
                this.adminService.showOffdutyMenu();
              }
              this.checkMediaTokens();
              this._flashMessagesService.show('Settings Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Settings Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  getAdminUsers() {
    // this.apiLink = 'admin/admin-user?page=' + page;
    this.apiService.getRequest('admin/admin-user').subscribe(
      (response: any) => {
        if(response.success) {
          this.users = response.data;
          // this.page = response.data.current_page;
          // this.collectionSize = response.data.total;
          // this.pageSize = response.data.per_page;
          // this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
    // this.apiService.getRequest('admin/admin-user').subscribe(
    //   (response: any) => {
    //     if(response.success) {
    //       this.users = response.data;
    //     }
    //   },
    //   (err: any) => console.log(err),
    // );
  }
  manageAdminSpecialPermissions(event,user,is_super_admin,permission) {
    this.toggleSelected = event.target;
    if(this.loggedUserId == user) {
      setTimeout(() => {
        event.target.checked = !event.target.checked;
      }, 50);
      this._flashMessagesService.show("Unable to change own permission", { cssClass: 'alert-danger' });
      return false;
    }
    if(is_super_admin) {
      this.confirmPermissionChangeForm.controls['user'].setValue(user);
      this.confirmPermissionChangeForm.controls['permission'].setValue(permission);
      this.confirmPermissionChangeForm.controls['status'].setValue(event.target.checked);
      this.modalRef = this.modal.open(this.confirmModal);
    } else {
      let postData = {
        'user' : user,
        'permission' : permission,
        'status' : event.target.checked
      }
      this.managePermissions(postData)
    }
  }

  changeAdminPermission() {
    let postData = {
      'user' : this.confirmPermissionChangeForm.value.user,
      'permission' : this.confirmPermissionChangeForm.value.permission,
      'status' : this.confirmPermissionChangeForm.value.status
    }
    this.managePermissions(postData)
  }

  managePermissions(postData) {
    this.apiService.postRequest('admin/manage-admin-special-access', postData).subscribe(
      (response: any) => {
          if (response.success)
            this._flashMessagesService.show(response.data.message, { cssClass: 'alert-success' });
          else
            this._flashMessagesService.show(response.data.message, { cssClass: 'alert-danger' });
          this.getAdminUsers()
          this.modalRef.close()
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
      );
  }

  cancelSelection() {
    this.toggleSelected.checked = !this.toggleSelected.checked;
    this.modalRef.close()
  }
  changeTip() {

    let tipChecked;
    let jobChecked;
    let zoneChecked;
    this.tipSaveBtn = false;
    this.jobSaveBtn = false;
    this.zoneSaveBtn = false;
    this.notifySettingsSaveBtn = false;

    tipChecked = document.getElementsByName('tip');
    for (var i = 0; i < tipChecked.length; i++) {
      if (tipChecked[i].type == 'checkbox' && tipChecked[i].checked) {
        this.tipSaveBtn = true;
      }
    }
    jobChecked = document.getElementsByName('job');
    for (var i = 0; i < jobChecked.length; i++) {
      if (jobChecked[i].type == 'checkbox' && jobChecked[i].checked) {
        this.jobSaveBtn = true;
      }
    }
    zoneChecked = document.getElementsByName('zone');
    for (var i = 0; i < zoneChecked.length; i++) {
      if (zoneChecked[i].type == 'checkbox' && zoneChecked[i].checked) {
        this.zoneSaveBtn = true;
      }
    }

    if(this.zoneSaveBtn && this.tipSaveBtn && this.jobSaveBtn){
      this.notifySettingsSaveBtn = true;
    }
    else
      this.notifySettingsSaveBtn = false;
  }

  removeImage(image) {
    let file = '';
    if(image == 'admin_login'){
      file = this.admin_login_url
      this.admin_login_url = null;
      this.isAdminImageUploaded = false;
    } else if(image == 'app_login'){
      file = this.app_login_url
      this.app_login_url = null;
      this.isAppImageUploaded = false;
    } else if(image == 'app_home'){
      file = this.app_home_url
      this.app_home_url = null;
      this.isAppHomeImageUploaded = false;
    }
    let postData = {
      'image': file,
    }
    this.apiService.postRequest('admin/remove-login-image', postData).subscribe(
      (response: any) => {
          this.errorMessage = null;
          if (response.success) {
              this._flashMessagesService.show("Removed Successfully!", { cssClass: 'alert-success' });
            } else {
              this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              this.errorMessage = response.message;
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );
  }
  imageUpload(event,type) {
    if (event.target) {
      var formData = new FormData;
      formData.append('icon',event.target.files[0]);
      formData.append('type',type);
      this.errorMessage = null;
      this.apiService.postRequest('admin/upload-login-images', formData).subscribe(
        (response: any) => {
            if (response.success) {
              if(type == 'admin_login'){
                this.admin_login_url = response.path;
                this.isAdminImageUploaded = true;
              } else if(type == 'app_login'){
                this.app_login_url = response.path;
                this.isAppImageUploaded = true;
              } else if(type == 'app_home'){
                this.app_home_url = response.path;
                this.isAppHomeImageUploaded = true;
              }
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  saveLoginImages() {
    if (this.loginImages.valid) {
      let postData = {
        'admin_login': this.admin_login_url,
        'app_login': this.app_login_url,
        'app_home': this.app_home_url
        ,
      }
      this.apiService.postRequest('admin/save-login-images', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show("Saved Successfully!", { cssClass: 'alert-success' });
                this.errorMessage = null;
              } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  isFormValid(): boolean {
    if(this.wasPopulated) {
      return true;
    }
    return Object.values(this.createSettings.controls).some(control => control.value);
  }
}
