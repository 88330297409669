export const environment = {
  production: false,
  apiUrl: 'https://oaklandcountymisheriff.ucpapp.com/api/',
  whitelist : [],
  clientName : "Oakland County Sheriff's Office",
  title : "Oakland Sheriff App By Stackbench | Admin",
  loginBg : "assets/images/sheriff-login-bg.jpeg",
  logo :'assets/images/oakland-logo-no-bg.png',
  loginLogo :'assets/images/oakland-logo-no-bg.png',
  logoText : 'assets/images/sheriff-logo-text.png',
  appName : "Oakland County Sheriff's Office",
  appLogoName : 'OAKLAND',
  appLogoDesc : "COUNTY SHERIFF'S OFFICE",
  loginTitleFontSz: '27px',
  loginDescFontSz: '8px',
  loginTextColor: '#fff',
  sidebarTitleFontSz: '27px',
  sidebarDescFontSz: '8px',
  sidebarTextColor: '#000',
  googleApiKey: 'AIzaSyByuo9gaCy0uJBGYpdiS_V_iHzY_3t3Kfg',
  zoneText: 'Patrol',
  isZoneExternal: false,

  ReturnUrl: 'https://oaklandcountymisheriff.ucpapp.com/admin/settings',
  FacebookAppId : 5616482711761750,
  FacebookAppSecret : '0fabbca48dad2c078aa304b302ea5491',

  LinkedinClientId : '86gnzvhvv68rj9',
  LinkedunClientSecret : 'JfE3UT3cRmqS3TvA',
  offDutyTitle : 'Off Duty Jobs',
  singleOffDutyTitle : 'Off Duty Job',
  showOffdutyInSettings: true,
  showInmateInSettings: false,
  favIcon : "oakland-favicon.ico"
};

