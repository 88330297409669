import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'resource-edit',
  templateUrl: './resource-edit.component.html',
  styleUrls: ['./resource-edit.component.scss']
})
export class ResourceEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  resourceId: string;
  key:string = "";
  name: string;
  url:string = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  desc :string;
  link_name: string;
  link: string;

  @Input() pageTitle: string ;
  @Input() updateUrl: string ;  
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;

  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.resourceId = params['id'];
    });
    this.getResource();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': [''],
      'document': [''],
      'link': [''],
      'link_name': ['']
    });
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
    // if(!this.pageTitle) {
    //   this.pageTitle = "Resource";
    // }
    // if(!this.updateUrl) {
    //   this.updateUrl = 'admin/standard-page/' + this.resourceId;
    // }
  }
  getResource () {
    this.apiService.getRequest('admin/app-page/' + this.resourceId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.key = response.data.page_key;
          this.desc = response.data.desc;
          this.link = response.data.link;
          this.link_name = response.data.link_name;

          if(response.data.document_name && response.data.document_url && response.data.document){
            this.documentUploaded = {
              'doc_name': response.data.document_name,
              'doc_link': response.data.document_url,
              'document': response.data.document,
            }
            this.isDocUploaded = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'id' : this.resourceId,
        'name': this.editPageForm.value.name,
        'type': this.key,
        'desc': this.editPageForm.value.desc,
        'link': this.editPageForm.value.link,
        'link_name': this.editPageForm.value.link_name,
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['resource']);
                // }
              }
              this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.editPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }


  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
}
